var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "paymentplan-emails" },
    [
      _vm.warning_message
        ? _c(
            "el-alert",
            {
              class: { "has-contacts": _vm.contacts.length },
              attrs: { type: "error", closable: false },
            },
            [_vm._v(" " + _vm._s(_vm.warning_message) + " ")]
          )
        : _vm._e(),
      _vm.contacts.length
        ? [
            _c(
              "div",
              { staticClass: "paymentplan-emails-header" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 5, type: "flex", align: "middle" } },
                  [
                    _c(
                      "el-col",
                      { staticClass: "description", attrs: { span: 10 } },
                      [_vm._v(" Contact ")]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "email", attrs: { span: 10 } },
                      [_vm._v(" Email ")]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "center", attrs: { span: 2 } },
                      [_vm._v(" Select ")]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "center", attrs: { span: 2 } },
                      [_vm._v(" Address to ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "paymentplan-emails-client" },
              _vm._l(_vm.client_contacts, function (contact) {
                return _c(
                  "el-row",
                  {
                    key: `contact_${contact.email}`,
                    staticClass: "contact-row",
                    attrs: { gutter: 5, type: "flex", align: "middle" },
                  },
                  [
                    _c(
                      "el-col",
                      { staticClass: "description", attrs: { span: 10 } },
                      [_vm._v(" " + _vm._s(contact.name) + " ")]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "description", attrs: { span: 10 } },
                      [_vm._v(" " + _vm._s(contact.email) + " ")]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "center", attrs: { span: 2 } },
                      [
                        _c("el-checkbox", {
                          model: {
                            value: contact.selected,
                            callback: function ($$v) {
                              _vm.$set(contact, "selected", $$v)
                            },
                            expression: "contact.selected",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { staticClass: "center", attrs: { span: 2 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: contact.email },
                            model: {
                              value: _vm.addressee,
                              callback: function ($$v) {
                                _vm.addressee = $$v
                              },
                              expression: "addressee",
                            },
                          },
                          [_vm._v(" - ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "paymentplan-emails-users" },
              _vm._l(_vm.user_contacts, function (contact) {
                return _c(
                  "el-row",
                  {
                    key: `user_${contact.email}`,
                    staticClass: "contact-row",
                    attrs: { gutter: 5, type: "flex", align: "middle" },
                  },
                  [
                    _c(
                      "el-col",
                      { staticClass: "description", attrs: { span: 10 } },
                      [
                        _vm._v(" " + _vm._s(contact.name) + " "),
                        contact.role
                          ? _c(
                              "el-tag",
                              { attrs: { type: "info", size: "mini" } },
                              [_vm._v(" " + _vm._s(contact.role) + " ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { staticClass: "description", attrs: { span: 10 } },
                      [_vm._v(" " + _vm._s(contact.email) + " ")]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "center", attrs: { span: 2 } },
                      [
                        _c("el-checkbox", {
                          model: {
                            value: contact.selected,
                            callback: function ($$v) {
                              _vm.$set(contact, "selected", $$v)
                            },
                            expression: "contact.selected",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }